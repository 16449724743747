import dayjs from "dayjs";
import { t } from "./../../logic/translator.js";
import { createSearchParams } from "react-router-dom";

export const alertStatus = {
  new: { label: t(`Not Assigned`), color: "var(--dt-ui-resolved-200)" },
  inprogress: { label: t(`In Progress`), color: "var(--dt-ui-medium-100)" },
  escalated: { label: t(`Alert Escalated`), color: "var(--dt-ui-critical-300)" },
  resolved: { label: t(`Resolved`), color: "var(--dt-ui-polar-glacier-200)" },
};

export const defaultAlertsTabFilters = {
  statuses: ["new", "inprogress", "escalated"].join(","),
  hostname: null,
  types: null,
  regions: null,
  assignee_id: null,
  status_changed_start_time: null,
  status_changed_end_time: null,
};

// NOTE: Don't use toISOString()
export const now = dayjs().utc().second("00").format("YYYY-MM-DDTHH:mm:ss") + ".000000Z";
export const fiveDaysAgo = dayjs().utc().second("00").subtract(5, "day").startOf("day").format("YYYY-MM-DDTHH:mm:ss") + ".000000Z";

export const escalationsNavigateProps = {
  pathname: "overview/escalations",
  search: createSearchParams({ status_changed_start_time: fiveDaysAgo, status_changed_end_time: now }).toString(),
};

export const defaultEscalatedTabFilters = {
  statuses: "escalated",
  status_changed_start_time: fiveDaysAgo,
  status_changed_end_time: now,
};

export const isValidHostname = (val) => {
  let pattern = /^[a-z]+[0-9]?-[0-9]+-[0-9]*/;
  return pattern.test(val);
};

export function checkForPresetMatch(start, end) {
  if (!start || !end) return null;
  const startFormatted = dayjs(start).utc();
  const endFormatted = dayjs(end).utc();
  if (endFormatted.format("YYYY-MM-DD") !== dayjs().utc().format("YYYY-MM-DD")) return null;
  const diff = Math.abs(Math.floor(startFormatted.diff(endFormatted, "days")));
  const duration = diff === 1 ? 24 : [3, 5, 7].includes(diff) ? diff : Math.round(diff / 7);
  const unit = [3, 5, 7].includes(diff) ? "Days" : diff === 1 ? "Hours" : "Weeks";
  return `Last ${duration} ${unit}`;
}
