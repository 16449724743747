import React, { useState } from "react";
import styled from "styled-components";
import { useProductLoginMutation } from "../../logic/api.js";
import { useActiveClientId } from "../../logic/hooks.js";
import { Tile, Tooltip, useColorTheme } from "@darktrace/ui-components";
import { ProductLoginModal } from "./ProductLoginModal.jsx";
import visualiserNew from "../../../public/dt-assets/icon/2x/dt-icon-threat-visualizer-gradient.png";
import { PRODUCT_DESCRIPTIONS, PRODUCT_INFO_MAP } from "../../logic/util.js";

const StyledProductTile = styled.button`
  position: relative;
  max-height: 100%;

  > .dt-ui-tooltip-target {
    z-index: 1;
    position: absolute;
    top: 0.8rem;
    left: 0.8rem;
  }

  > .instance-count {
    position: absolute;
    top: 0.8rem;
    right: 1.2rem;
    background-color: var(--dt-ui-card-container-header-bg);
    padding: 0.4rem 0.8rem;
    border-radius: 0.4rem;
  }

  background-color: var(--dt-ui-field-bg-muted);
  border-radius: 6px;
  border: 1px solid var(--dt-ui-field-border);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  height: fit-content;
  min-width: 20rem;
  border: 1px solid var(--dt-ui-field-border);
  aspect-ratio: 5 / 3;
  flex: 1;
  overflow: hidden;

  &:hover:not(:disabled) {
    background-color: var(--dt-ui-field-hover-bg);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;

    &.pending {
      cursor: progress;
    }
  }

  .header {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;

    img.product-icon {
      max-width: 65%;
      height: auto;
      max-height: 6rem;
    }
  }

  .footer {
    align-self: stretch;
    border-top: 1px solid var(--dt-ui-field-border);
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1.4rem;
    font-weight: 600;
    height: 4.8rem;
    padding-inline: 2rem;

    .darktrace-text {
      font-weight: 700;
    }
  }
`;

const StyledServiceTile = styled(Tile)`
  &.pending {
    cursor: progress;
  }

  .service-tile-header-content {
    display: flex;
    align-items: center;
    gap: 1rem;

    img.product-icon {
      max-width: 2rem;
      height: 1.6rem;
    }
  }
`;

export function ProductTile({ serviceType, serviceId, serviceLabel, instances = [], productPath, type = "product" }) {
  const [colorTheme] = useColorTheme();
  const activeClientId = useActiveClientId();
  const [showProductLoginModal, setShowProductLoginModal] = useState();
  const productLogoImage = PRODUCT_INFO_MAP[serviceType]?.[colorTheme === "polar-light" ? "logoLight" : "logoDark"] || visualiserNew;
  const productName = PRODUCT_INFO_MAP[serviceType]?.name ?? serviceLabel;

  const { mutate: loginMutation, isPending } = useProductLoginMutation({
    clientId: activeClientId,
    serviceId,
    serviceLabel: productName,
    productPath,
  });
  const productTooltip = PRODUCT_DESCRIPTIONS?.[serviceType];
  const shouldUseProductLoginModal = instances.length > 1;
  const disabled = isPending || instances.every((instance) => instance.disabled);

  function handleLogin() {
    if (disabled) return;

    // show modal if there are multiple instances
    if (shouldUseProductLoginModal) {
      setShowProductLoginModal((prev) => !prev);
      return;
    }

    loginMutation({ instanceId: instances[0].instanceId });
  }

  return (
    <>
      {type === "service" ? (
        <StyledServiceTile
          onClick={handleLogin}
          disabled={disabled}
          className={isPending ? "pending" : ""}
          noColorBar
          key={serviceType}
          header={
            <div className="service-tile-header-content">
              <img className="product-icon" src={productLogoImage} />
              <span>{productName}</span>
              {productTooltip && <Tooltip text={productTooltip}></Tooltip>}
            </div>
          }
          headerRight={<i className="fa fa-arrow-right" />}
        />
      ) : (
        <StyledProductTile onClick={handleLogin} disabled={disabled} className={isPending ? "pending" : ""}>
          {productTooltip && <Tooltip text={productTooltip}></Tooltip>}
          {instances.length > 1 && <div className="instance-count">{instances.length}</div>}
          <div className="header">
            <img className="product-icon" src={productLogoImage} />
          </div>
          <div className="footer">
            <span className="darktrace-text">Darktrace</span>
            <span className="product-name">{productName}</span>
          </div>
        </StyledProductTile>
      )}

      {shouldUseProductLoginModal && (
        <ProductLoginModal
          open={showProductLoginModal}
          productName={productName}
          onClose={() => setShowProductLoginModal(false)}
          serviceId={serviceId}
          instances={instances}
        />
      )}
    </>
  );
}
